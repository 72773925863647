<template>
  <div>
    <b-row
      class="match-height"
    >
      <b-col
        v-if="activeSubscription"
        sm="12"
        md="12"
      >
        <b-card>
          <b-button
            v-if="!activeSubscription.is_paid"
            variant="primary"
            class="bg-primary p-1 px-3 text-white shadow cursor-pointer"
            style="position:absolute;top:0; right:0;border-radius: 0px 8px 0px 15px;z-index:300;"
            :to="{ name: 'subscribe', params: { id: activeSubscription.id } }"
          >
            <span class="cursor-pointer">Pay Now</span>
          </b-button>
          <b-row class="ma-0 h-full">
            <b-col>
              <b-card-title class="d-flex justify-content-start mt-0 mb-1">
                <h2>Active Subscription</h2>
                <div class="ml-2">
                  <b-badge
                    :variant="`light-${activeSubscription.is_paid ? 'primary' : 'danger'}`"
                  >
                    {{ activeSubscription.is_expire ? 'Expired' : activeSubscription.is_paid ? 'Paid' : 'Unpaid' }}
                  </b-badge>
                </div>
              </b-card-title>
              <h4>Subscription value - {{ activeSubscription.price }}</h4>
              <div class="progress-wrapper">
                <b-card-text class="font-weight-bolder d-flex justify-content-end">
                  {{ subscriptionTimer.remainingDays }} of {{ subscriptionTimer.totalDays }} Days
                </b-card-text>
                <b-progress
                  v-model="subscriptionTimer.remainingDays"
                  max="30"
                />
                <b-card-text class="mt-0">
                  {{ subscriptionTimer.remainingDays }} Day{{ subscriptionTimer.remainingDays > 0 ? 's' : '' }} Remaining
                </b-card-text>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-card no-body>
      <b-row class="d-flex justify-content-between align-items-center ml-1 mr-2">
        <b-card-header>
          <h4>Billing</h4>
        </b-card-header>
        <div>
          <ReportTableButtons
            :items="rows"
            :json_fieldz="json_fields"
            :name="`Subscriptions-Billing - Page ${page}.xls`"
            :bulkname="`Subscriptions-Billing.xls`"
            :fetch="fetchBillingListNoPagination"
            @refresh="onClickRefresh"
          />
        </div>
      </b-row>
      <b-row>
        <b-col>
          <b-overlay
            id="overlay-background"
            blur="10px"
            opacity="0.30"
            rounded="sm"
            variant="light"
          >
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="rows"
              :fields="fields"
              show-empty
            >
              <template #empty>
                <TableDataFetching
                  :rows="rows"
                  :data-loading="dataLoading"
                />
              </template>
              <template #cell(client_name)="data">
                <div
                  class="d-flex align-items-center"
                >
                  <b-avatar
                    v-if="data.item.logo === null"
                    :text="avatarText(data.value)"
                    size="32"
                    variant="light-primary"
                  />
                  <b-avatar
                    v-else
                    :src="`${data.item.logo}`"
                    size="32"
                  />
                  <div>
                    <div
                      class="font-weight-bolder ml-1 text-primary"
                    >
                      {{ data.value }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(paid_via)="data">
                {{ data.value === 'bank_transfer' ? 'Bank Transfer' : (data.value === 'pay_here' ? 'Pay here' : (data.value === 'credit_basis' ? 'Credit basis' : '---')) }}
              </template>
              <template #cell(invoice_period)="data">
                <div class="width-190 d-flex flex-column align-items-start">
                  <div>
                    <b>Start Date&nbsp;:</b>&nbsp;&nbsp;{{ data.item.start_date || '---' }}
                  </div>
                  &nbsp;
                  <div>
                    <b>End Date&nbsp;:</b>&nbsp;&nbsp;{{ (data.item.end_date ) || '---' }}
                  </div>
                </div>
              </template>
              <template #cell(total_amount)="data">
                {{ data.value || '---' }}
              </template>
              <template #cell(start_date)="data">
                {{ __dateTimeFormatter(data.value) || '---' }}
              </template>
              <template #cell(end_date)="data">
                {{ __dateTimeFormatter(data.value) || '---' }}
              </template>
              <template #cell(paid_date)="data">
                {{ __dateTimeFormatter(data.value) || '---' }}
              </template>
              <template #cell(payment_due_date)="data">
                {{ __dateTimeFormatter(data.value) }}
              </template>
              <template #cell(created_at)="data">
                {{ __dateTimeFormatter(data.value) }}
              </template>
              <template #cell(payment_status)="data">
                <b-badge
                  :variant="`light-${data.value === 'due' ? 'danger' : (data.value === 'paid' ? 'warning' : 'success')}`"
                >
                  {{ data.value === 'due' ? 'DUE' : (data.value === 'paid' ? 'PAID' : 'APPROVED') }}
                </b-badge>
              </template>
              <template #cell(action)="data">
                {{ data.value }}
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  :class="data.length !== 1 ? 'dropdown-no-scroll':''"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="12"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click="openPackageListModal(data.item.id)"
                  >
                    <feather-icon icon="EyeIcon" />
                    Package Details
                  </b-dropdown-item>
                  <b-dropdown-item
                    :to="{ name: 'subscribe', params: { id: data.item.id } }"
                  >
                    <feather-icon icon="CreditCardIcon" />
                    Pay Now
                  </b-dropdown-item>
                  <b-dropdown-item
                    :to="{ name: 'view-invoice', params: { id: data.item.id } }"
                  >
                    <feather-icon icon="LogInIcon" />
                    Download Invoice
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-overlay>

          <div class="mx-2 mb-2">
            <b-row
              cols="12"
            >
              <b-col
                class="d-flex align-items-center justify-content-sm-start"
                md="3"
              >
                <label class="width-75">Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block width-100"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                class="d-flex align-items-center justify-content-sm-end"
                md="9"
              >
                <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="(meta.total)? meta.total : 0"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <view-payment-modal ref="viewPaymentModal" />
    <package-list-modal ref="packageListModal" />
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BPagination,
  BTable,
  BOverlay,
  BCardHeader,
  BCardText,
  BCardTitle,
  BButton,
  BBadge,
  BDropdown,
  BDropdownItem,
  BProgress,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Filter from '@core/mixins/filter'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import ViewPaymentModal from '@/components/SubscriptionPlan/ViewPaymentModal.vue'
import PackageListModal from '@/components/subscription/PackageListModal.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const subscriptionRepository = RepositoryFactory.get('subscription')

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BCol,
    BRow,
    BCard,
    BPagination,
    BTable,
    BCardHeader,
    BCardText,
    BBadge,
    BCardTitle,
    BButton,
    BOverlay,
    BProgress,
    BDropdown,
    BDropdownItem,
    vSelect,
    ViewPaymentModal,
    PackageListModal,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      total: 0,
      meta: {},
      dataLoading: false,
      sort: '',
      filters: {},
      subscriptionTimer: {
        total_days: '',
      },
      subscriptionId: null,
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      fields: [
        {
          key: 'id',
          label: '#',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          key: 'created_at',
          label: 'Created Date',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'invoice_period',
          label: 'Invoice Period',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'inv_ref_no',
          label: 'Reference Number',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'billed_order_count',
          label: 'Orders handled',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'total_amount',
          label: 'Total Amount',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'paid_via',
          label: 'Paid Via',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'paid_date',
          label: 'Paid Date',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'payment_status',
          label: 'Payment status',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'payment_due_date',
          label: 'Due Date',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
          thClass: 'text-left',
          tdClass: 'text-center',
        },
      ],
      rows: [],
      activeSubscription: null,
      json_fields: {
        'Created Date': {
          field: 'created_at',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        'Start Date': 'start_date',
        'End Date': 'end_date',
        Package: 'subscription_list.subscription.package.package_name',
        'Orders handled': 'billed_order_count',
        'Invoice Period': 'invoice_period',
        'Reference Number': 'inv_ref_no',
        'Total Amount': 'total_amount',
        'Paid Via': 'paid_via',
        'Paid date': {
          field: 'paid_date',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        'Payment status': 'payment_status',
        'Due Date': 'payment_due_date',
      },
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchBillingList()
      },
    },
    deep: true,
    page() {
      this.fetchBillingList()
    },
    perPage() {
      this.fetchBillingList()
    },
  },
  async mounted() {
    this.subscriptionId = Number(this.$route.params.id)
    // await this.fetchActiveSubscription()
  },
  methods: {
    // async fetchActiveSubscription() {
    //   try {
    //     const { data } = (await subscriptionRepository.geActiveSubscription())
    //     this.activeSubscription = data.data
    //     this.subscriptionTimer.totalDays = Math.floor((new Date(this.activeSubscription.ends_at) - new Date(this.activeSubscription.starts_at)) / (1000 * 60 * 60 * 24)) + 1
    //     this.subscriptionTimer.remainingDays = this.subscriptionTimer.totalDays - Math.floor((new Date() - new Date(this.activeSubscription.starts_at)) / (1000 * 60 * 60 * 24)) - 1
    //   } catch (e) {
    //     this.convertAndNotifyError(e)
    //   }
    // },
    async fetchBillingList() {
      this.dataLoading = true
      this.rows = []
      try {
        const { data } = (await subscriptionRepository.getBillingList(this.page, this.perPage))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async fetchBillingListNoPagination() {
      try {
        const { data } = (await subscriptionRepository.getBillingListNopagination())
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async onClickDownload(subscriptionId) {
      try {
        const { data } = await subscriptionRepository.getSubscriptionById(subscriptionId)
        // Create a download link for the data
        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(new Blob([JSON.stringify(data)], { type: 'application/json' }))
        downloadLink.download = 'invoice.json'
        downloadLink.click()
        // Clean up the object URL
        URL.revokeObjectURL(downloadLink.href)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    onClickViewPayment(id) {
      this.$refs.viewPaymentModal.openModal(id)
    },
    onClickMakePayment() {
      this.$router.push({
        name: 'subscribe',
      })
    },
    openPackageListModal(id) {
      this.$refs.packageListModal.openModal(id)
    },
    handleChangePage(page) {
      this.page = page
      this.fetchBillingList()
    },
    filterQueryUpdate() {
      this.fetchBillingList()
    },
    sortChanged(e) {
      const key = e.sortBy
      if (key) {
        const active = e.sortDesc ? '-' : ''
        this.sort = active + key
        this.fetchBillingList()
      }
    },
    onClickRefresh() {
      // this.fetchActiveSubscription()
      this.fetchBillingList()
    },
  },
}
</script>
